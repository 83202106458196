/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"

import { Layout, Seo } from "../../layout"
import { FindOutMoreButton, IPhoneCarousel, img } from "../../components"

export default function IndexPage(props) {
  return (
    <Layout header="light" path={props.path}>
      <Seo
        script="https://trailguide.net/js/order_plugin.js"
        title="Add your bike shop to Trailguide"
      />

      <div
        className="relative w-full min-h-screen tg-front-image text-white px-4 sm:px-8"
        style={{
          backgroundImage: `url("${img("webapp/earnmoney.jpg")}")`,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="flex mx-auto max-w-4xl pt-24">
          <div>
            <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl text-left m-0">
              Make your bike shop visible on Trailguide
            </h1>
            <p className="text-base sm:text-lg md:text-xl mt-8 md:mt-16">
              Trailguide is an app for mountain biking. It gives bikers easy
              access to a large network of trails added by local bike
              enthusiasts. The app also supports other types of cycling such as
              cross country and road biking.
            </p>
            <p className="text-base sm:text-lg md:text-xl my-6">
              The app has over 10.000 weekly users looking for new trails and
              getting help with navigation. Maybe even on a bike you sold them.
            </p>
            <p className="text-base sm:text-lg md:text-xl my-6">
              With a listing of your bike shop in Trailguide, we will send you a
              small package of stickers with a QR code that your customers can
              scan to open the app and start their next adventure.
            </p>

            <FindOutMoreButton
              className="hidden md:inline-block mt-4 mb-8 shadow-md border-2 border-green-100"
              to="#order"
            >
              Click her to order
            </FindOutMoreButton>
          </div>
          <IPhoneCarousel
            top="-3.6%"
            images={[
              {
                label: "An example of visibility in Trailguide",
                src: img("/website/local-service-map-iphone-tretterud.jpg"),
              },
              {
                label:
                  "You are visible in trail descriptions within 20km from your shop",
                src: img("/website/local-service-page-iphone-tretterud.jpeg"),
              },
            ]}
            resetClass="relative hidden md:block max-w-56 flex-none w-64 mt-8 ml-24"
            color="white"
          />
        </div>
      </div>

      <div id="order" className="pt-8 md:pt-24" />
      <div
        className="max-w-3xl mx-auto px-4 md:px-8"
        style={{ minHeight: "500px" }}
      >
        <order-form header="false" />
      </div>
    </Layout>
  )
}
